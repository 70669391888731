<template>
  <div :class="`subjects_${getSize()}`" class="card SubjectCard" :style="{left: b1[index].x + '%', top: b1[index].y + '%'}">
    {{ str }}
  </div>
</template>

<script>
export default {
  name: "SubjectCard",
  props: {
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    str: {
      type: String,
      required: false,
    }
  },
  data() {
    return {}
  },
  setup() {
    const b1 = [
      {x: 1.72, y: 21.67},
      {x: 17.34, y: 26.85},
      {x: 68.8, y: 24.81},
      {x: 84.38, y: 24.81},
      {x: 0, y: 30.39},
      {x: 24.43, y: 35.56},
      {x: 45.36, y: 33.06},
      {x: 55.16, y: 34.07},
      {x: 80.09, y: 37.78},
      {x: 5.94, y: 41.57},
      {x: 35.05, y: 42.22},
      {x: 74.79, y: 47.59},
      {x: 18.85, y: 47.5},
      {x: 55.16, y: 50},
      {x: 4.11, y: 56.48},
      {x: 21.51, y: 57.5},
      {x: 42.66, y: 58.43},
      {x: 77.03, y: 56.02},
      {x: 4.17, y: 65.83},
      {x: 18.44, y: 71.76},
      {x: 41.51, y: 69.44},
      {x: 59.06, y: 68.06},
      {x: 74.9, y: 71.3},
      {x: 2.14, y: 77.04},
      {x: 20.68, y: 79.81},
      {x: 37.29, y: 79.72},
      {x: 52.71, y: 79.72},
      {x: 1.72, y: 85},
      {x: 22.4, y: 90.56},
      {x: 62.97, y: 88.61},
      {x: 78.54, y: 88.61},
    ]
    const getSize = () => {
      let num = Math.random() * 10
      if (num < 4) {
        return 'small'
      } else if (num < 8) {
        return 'middle'
      } else {
        return 'large'
      }
    }


    return {
      b1,
      getSize
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.SubjectCard {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Microsoft YaHei;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.subjects_small {
  background-image: url("../../assets/images/ic_btn_s@2x.png");
  @include backgroundMix;
  position: absolute;
  width: 283px;
  height: 69px;
  font-size: 18px;
  @media(max-width:  1280px){
    width: 168px;
    height: 46px;
    font-size: 12px;
  }
}

.subjects_middle {
  background-image: url("../../assets/images/ic_btn_m@2x.png");
  @include backgroundMix;
  position: absolute;
  width: 275px;
  height: 99px;
  font-size: 20px;
  @media(max-width:  1280px){
    width: 183px;
    height: 66px;
    font-size: 15px;
  }
}

.subjects_large {
  background-image: url("../../assets/images/ic_btn_l@2x.png");
  @include backgroundMix;
  position: absolute;
  width: 390px;
  height: 130px;
  font-size: 26px;
  @media(max-width:  1280px){
    width: 260px;
    height: 86px;
    font-size: 19px;
  }
}
</style>
